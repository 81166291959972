<template>
  <section
    id="about-mission-vision"
    class="py-0"
  >
    <base-left-photo
      :src="require('@/assets/Ronghao_logo.png')"
      subheading="RONGHAO ROBOT TECHNOLOGY"
      html="<b>Ronghao (Shandong) Robot Technology Co., Ltd.</b> was established in June 2021. Located at No. 9009, Qinglongshan Road, High-Tech Zone, Zibo City, Shandong Province, its core business is in <b>intelligent manufacturing</b> and <b>industrial upgrading.</b> Ronghao Robot Technology specializes in providing intelligent factory solutions through automation and robotic technology. <br><br>We are confident in giving on customers the optimal, cost-efficient and reliable manufacturing solution to gain the leading-edge in their respective industry. <br><br>Revolutionizing production methods, intelligent logistics equipment has begun to gradually replace traditional manual operations. Automation coupled with robotic technology will improve quality as well as reduce human error, while significantly improving yield and manufacturing productivity. <br><br>Leverage the power of automation and artificial intelligence towards revolutionizing your business with us at Ronghao Robot Technology."
      color="black--text"
    >
    </base-left-photo>
  </section>
</template>

<script>
  export default {
    name: 'SectionAboutMissionVision',
  }
</script>
